import {returnErrors} from './errorActions';
import axios from 'axios';
import {getPartnerId} from "../utils/authUtils"
import {
    STATISTIC_DATA_LOADING,
    STATISTIC_DATA_LOADED,
    CLEAR_STATISTIC_DATA,
    DETAILED_STATISTIC_LOADING,
    DETAILED_STATISTIC_LOADED,
    DETAILED_STATISTIC_DOWNLOADING,
    DETAILED_STATISTIC_DOWNLOADED,
} from './actionTypes';
import {getConfig} from "./authActions";
import {
    DATE_DAY, LTV_SERVICE_TYPE,
    MANAGER_ANALYSIS,
    MANAGER_LTV_ANALYSIS,
    MANAGER_MAIN,
    MANAGER_REFERRER,
    PARTNER_ANALYSIS,
    PARTNER_MAIN
} from "../utils/constants";
import {deductTimezoneOffset, getIsoLocalString} from "../utils/dateUtils";


export const getStatistic = (filters, statisticType, reportType, callbackFunction) => (dispatch, getState) => {
    if (!statisticType) {
        return;
    }
    switch (statisticType) {
        case (PARTNER_MAIN):
        case (PARTNER_ANALYSIS):
            getPartnerStatistic(filters, statisticType, reportType, callbackFunction, dispatch, getState);
            break;
        case (MANAGER_MAIN):
        case (MANAGER_ANALYSIS):
        case (MANAGER_LTV_ANALYSIS):
        case (MANAGER_REFERRER):
            getManagerStatistic(filters, statisticType, reportType, callbackFunction, dispatch, getState);
            break;
        default:
            break;
    }
};

const getPartnerStatistic = (filters, statisticType, reportType, callbackFunction, dispatch, getState) => {
    dispatch({ type: STATISTIC_DATA_LOADING });

    const partnerId = "&partnerId=" + getPartnerId();
    const groups = filters.groups ? filters.groups.filter(group => group && group.value && group.value !== "") : [{value: DATE_DAY}];
    const groupsParameter = "&groups=" + groups.map(group => group.value).join(",");
    const fromDate = filters.fromDate ? "&from=" + getIsoLocalString(filters.fromDate) : "";
    const toDate = filters.toDate ? "&to=" + getIsoLocalString(filters.toDate) : "";
    const offerIds = filters.offers.length > 0 ? "&offerIds=" + filters.offers.map(offer => offer.ids).join(",") : "";
    let operatorIds = filters.operators.length > 0 ? "&operatorIds=" + filters.operators.map(operator => operator.id).join(",") : "";
    if (operatorIds === "" && filters.countries.length > 0) {
        operatorIds = "&operatorIds=" + filters.countries.flatMap(country => country.operatorIds).join(",");
    }
    axios
        .get(process.env.REACT_APP_API_URL + '/statistic/partner?type=' + statisticType + partnerId + groupsParameter
            + fromDate + toDate + offerIds + operatorIds, getConfig(getState))
        .then(res =>
            dispatch({
                type: STATISTIC_DATA_LOADED,
                payload: {statisticData : res.data, reportType: reportType}
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

const getManagerStatistic = (filters, statisticType, reportType, callbackFunction, dispatch, getState) => {
    dispatch({ type: STATISTIC_DATA_LOADING });
    const groups = filters.groups ? filters.groups.filter(group => group && group.value && group.value !== "") : [{value: DATE_DAY}];
    const fromDate = filters.fromDate ? "&from=" + getIsoLocalString(filters.fromDate) : "";
    const toDate = filters.toDate ? "&to=" + getIsoLocalString(filters.toDate) : "";
    const offerIds = filters.offers.length > 0 ? "&offerIds=" + filters.offers.flatMap(offer => offer.ids).join(",") : "";
    let partnerTypeIds = filters.partners.length > 0 ? filters.partners.filter(item => item.isGroup).map(item => item.partnerTypeId).join(",") : [];
    partnerTypeIds = partnerTypeIds.length > 0 ? ("&partnerTypeIds=" + partnerTypeIds) : "";
    let partnerIds = filters.partners.length > 0 ? filters.partners.filter(item => !item.isGroup).map(item => item.id).join(",") : [];
    partnerIds = partnerIds.length > 0 ? ("&partnerIds=" + partnerIds) : "";

    let operatorIds = filters.operators.length > 0 ? "&operatorIds=" + filters.operators.map(operator => operator.id).join(",") : "";
    if (operatorIds === "" && filters.countries.length > 0) {
        operatorIds = "&operatorIds=" + filters.countries.flatMap(country => country.operatorIds).join(",");
    }

    let groupsParameter, serviceGroups, serviceType, serviceIds, payoutType;

    serviceGroups = serviceType = serviceIds = payoutType = "";

    if( statisticType !== MANAGER_LTV_ANALYSIS) {
        groupsParameter = "&groups=" + groups.map(group => group.value).join(",");

        serviceType = filters.serviceType ? ("&serviceType=" + filters.serviceType.value) : "";
        if( statisticType === MANAGER_MAIN) {
            serviceGroups = filters.serviceGroups.length > 0 ? "&serviceGroupIds=" + filters.serviceGroups.filter(service => service.isGroup).map(service => service.id).join(",") : "";
            serviceIds = filters.serviceGroups.length > 0 ? "&externalServiceIds=" + filters.serviceGroups.filter(service => !service.isGroup).map(service => service.externalId).join(",") : "";
        }
    } else {
        groupsParameter = "&groups=" + groups.map(group => group.value).join(",") +
            (!groups.find(group => group.value === DATE_DAY) ? "," + DATE_DAY : "") +
            (!filters.ltvServiceType?.serviceIds && !groups.find( group => group.value === LTV_SERVICE_TYPE ) ? "," + LTV_SERVICE_TYPE : "");

        payoutType = filters.payoutType?.value ? "&offerBillingTypes=" + filters.payoutType.value : "";
        serviceIds = filters.ltvServiceType?.serviceIds ? "&externalServiceIds=" + filters.ltvServiceType.serviceIds : "";

    }

    axios
        .get(process.env.REACT_APP_API_URL + '/statistic/manager?type=' + statisticType + groupsParameter
            + fromDate + toDate + offerIds + operatorIds + partnerTypeIds + partnerIds + serviceType + serviceGroups + payoutType + serviceIds, getConfig(getState))
        .then(res =>
            dispatch({
                type: STATISTIC_DATA_LOADED,
                payload: {statisticData : res.data, reportType: reportType}
            })
        )
        .then(() => callbackFunction && callbackFunction())
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const clearStatisticData = () => (dispatch, getState) => {
    dispatch({ type: CLEAR_STATISTIC_DATA});
};

export const getDetailedStatistic = (searchData) =>  (dispatch, getState) => {
    if (!searchData || getState().statisticReducer.loadingDetailedStatistic) {
        return;
    }
    dispatch({ type: DETAILED_STATISTIC_LOADING });
    let searchParams = Object.keys(searchData).map(key => {
        if (key !== "groupName") {
            return key + "=" + searchData[key];
        }
    }).join("&");
    const path = searchData.isReferralsDetails ? '/statistic/detailed/referrer?' : '/statistic/detailed/clicks?';
    axios
        .get(process.env.REACT_APP_API_URL + path + searchParams, getConfig(getState))
        .then(res =>
            {
                dispatch({
                    type: DETAILED_STATISTIC_LOADED,
                    payload: res.data,
                })
            }
        )
        // .then(() => callbackFunction && callbackFunction())
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const exportDetailedStatistic = (searchData) =>  (dispatch, getState) => {
    if (!searchData || getState().statisticReducer.downloadingDetailedStatistic) {
        return;
    }
    searchData.limit = 0;
    dispatch({ type: DETAILED_STATISTIC_DOWNLOADING });
    let searchParams = Object.keys(searchData).map(key => {
        if (key !== "groupName") {
            return key + "=" + searchData[key];
        }
    }).join("&");
    axios
        .get(process.env.REACT_APP_API_URL + '/report/statistic/detailed?' + searchParams, getConfig(getState))
        .then(res =>
            {
                const blob = new Blob(["\ufeff", res.data],{ type: 'text/csv;charset=UTF-8' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                const date = deductTimezoneOffset(new Date());
                const filename =  "am-" + (searchData.isClickDetails ? "clicks" : "trafficback") + "-" + date.getFullYear() + date.getMonth()
                    + date.getDate() + "-" + date.getHours() + date.getMinutes() + ".csv";
                link.download = filename;
                link.click();
                dispatch({
                    type: DETAILED_STATISTIC_DOWNLOADED,
                })
            }
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};



